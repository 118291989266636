import {ALLOWED_CUSTOMER_EQUIPMENT, ANY, MOTORHOME, TENT, TENT_TRAILER, TRAILER, VAN} from "../../constants/common";

export default {
  SPOT_LIST: "Liste des emplacements",
  ADD_SPOT: "Ajouter un emplacement",
  SPOT_ADDED: "Emplacement ajouté",
  EDIT_SPOT: "Modifier l'emplacement",
  SPOT_UPDATED: "Emplacement mis à jour",
  DELETE_THIS_SPOT: "Voulez-vous supprimer l’ emplacement sélectionné ?",
  DELETE_SPOT_SUCCESS: "La suppression de l'emplacement a été effectuée avec succès",
  SPOT_DELETED: "Emplacement supprimé",
  SPOT_GENERAL_INFORMATION: "Informations générales",
  SPOT_SPECIFIC_INFORMATION: "Informations spécifiques",
  SPOT_CAPACITY: "Capacité",
  SPOT_PET_CAPACITY: "Animaux",
  SPOT_PETS_CAPACITY: "Nombre d'animaux",
  SPOT_CHILDREN_CAPACITY: "Nombre d'enfants",
  SPOT_ADULT_CAPACITY: "Nombre d'adultes",
  SPOT_NAME: "Nom de l'emplacement",
  SPOT_TYPE: "Type d'emplacement",
  SPOT_NUMBER: "N° d'identification",
  SPOT_EQUIPMENTS: "Equipements",
  SPOT_SHEET: "Fiche camping",
  SPOT_LENGTH: "Longueur de l'emplacement",
  SPOT_SCHEDULE: "Horaire",
  SPOT_ARRIVAL_TIME: "Heure d'arrivée",
  SPOT_DEPARTURE_TIME: "Heure de départ",
  SPOT_INCLUDED_EQUIPMENT: "Equipement inclus",
  SPOT_INCLUDED_EQUIPMENT_FOR: "Equipement inclus pour",
  SPOT_GALLERY: "Images",
  SPOT_MAIN_IMAGE: "Image principale",
  SPOT_OTHER_IMAGE: "Autres images",
  SPOT_STATUS_AVAILABLE: "Disponible",
  SPOT_STATUS_NOT_AVAILABLE: "Non disponible",
  CLEAN_CONDITION: "État",
  SPOT_CONDITION_CLEAN: "Propre",
  SPOT_CONDITION_DIRTY: "Sale",
  SPOT_LENGTH_FEET: "pieds",
  EQUIPMENT_LENGTH: "Longueur de l'équipement",
  SPOT_AREA: "Superficie du terrain ",
  PASSENGER_EXTENSIONS: "Nombre d'extensions côté passager",
  DRIVER_EXTENSIONS: "Nombre d'extensions côté conducteur",
  ALLOWED_EQUIPMENT: "Équipement de camping permis",
  ALLOWED_EQUIPMENT_ANY: "Tous",
  ALLOWED_EQUIPMENT_TENT: "Tente",
  ALLOWED_EQUIPMENT_TENT_TRAILER: "Tente-roulotte",
  ALLOWED_EQUIPMENT_TRAILER: "Roulotte",
  ALLOWED_EQUIPMENT_VAN: "Van",
  ALLOWED_EQUIPMENT_MOTORHOME: "Motorisé",
  BEDS_KING_QTY: "Quantité de lits king",
  BEDS_QUEEN_QTY: "Quantité de lits queen",
  BEDS_DOUBLE_QTY: "Quantité de lit double",
  BEDS_TWIN_QTY: "Quantité de lit jumeau",
  BATHROOM_SHOWER_QTY: "Quantité de salle de bain avec douche",
  BATHROOM_BATHTUB_QTY: "Quantité de salle de bain avec baignoire ",
  SAME_SPOTS: 'Un ou plusieurs emplacements ont déjà été enregistrés avec le nom {name}. S\'agit-il du même emplacement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA: "Vous n’avez ajouté aucun emplacement pour le moment, cliquer sur le bouton pour ajouter.",
  SPOT_DETAILS: "Détail d'un emplacement",
};
