export default {
  BOOKING_LIST: "Liste des réservations",
  PENDING_BOOKING_LIST: "Liste des réservations en cours",
  ADD_BOOKING: "Ajouter réservation",
  BOOKING_ADDED: "Réservation confirmée",
  EDIT_BOOKING: "Modifier la réservation",
  BOOKING_UPDATED: "Réservation mis à jour",
  DELETE_THIS_BOOKING: "Supprimer cette réservation ?",
  BOOKING_DELETED: "Réservation supprimée",
  CAPACITY: "Nombre de personnes",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Animaux",
  CHILDREN_CAPACITY: "Enfants",
  BOOK_SPOT: "Réserver place",
  STARTING_FROM: "À partir de",
  PRICE_PER_NIGHT: "par nuit",
  AVAILABILITY: "Disponibilité",
  AVAILABLE: "Disponible",
  NOT_AVAILABLE: "Non-disponible",
  PARTIALLY_AVAILABLE: "Partiellement disponible",
  FILTER_BY_SPOT_CATEGORY: "Terrain ou emplacement",
  FILTER_BY_SPOT_TYPE: "Service",
  FILTER_BY_SPOT_LENGTH: "Dimensions de l'équipement",
  FILTER_BY_EQUIPMENT: "Équipement",
  UNIT_FEET: "pieds",
  SPOTS_LIST_FOUND: "{total} emplacements trouvés",
  NEW_BOOKING: "Nouvelle réservation",
  BOOKING_CODE: "N° de confirmation",
  DURATION: "Nbr nuit",
  PRICE_PER_DAY: "Tarif/jour",
  ARRIVING_AT: "Arrivée",
  LEAVING_AT: "Départ",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Garantir",
  ADD_TO_CART: "Ajout",
  SPECIAL_REQUEST: "Demande du client",
  INTERNAL_NOTE: "Note interne",
  SELECT_CONFIRMATION_EXPIRATION_DATE: "Sélectionner une date d'expiration pour la confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Envoyer à l'adresse courriel du client",
  CONFIRM_AND_SEND: "Confirmer et envoyer",
};
